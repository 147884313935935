<!-- <div class="strip-head row   w-100 p-2 text-light">
  <div class="col"></div>
  <div class="col" style="font-size: 21px; color:#C0C0C0; margin-right: 10em; "><a href="http://ilkerdoviz.com/" target="_blank">İLKER
      DÖVİZ</a> Kurları İçin Tıklayınız </div>
  <div class="col" style="font-size: 16px; font-weight: 500;">{{ now| date:"fullDate":"":"tr-TR" }} {{now |
      date:'hh:mm:ss':"":"tr-TR"}}</div>
</div> -->
<div class="col-24  w-100" style="width:100%; ">
    <div class="g-0 row">
      <div class="col-md-9 col-sm-12">
        <div style="background-color: rgb(0, 0, 0);  height: 35px;">
          <div class="ng-star-inserted">
            <div class="e-rte-content" style="color: rgb(255, 255, 255);">
              <p style="text-align: center; position: relative; top:0.4em ;"><span style="font-size: 16pt;"><a class="e-rte-anchor"
                    href="http://ilkerdoviz.com/" title="" target="_blank"><span
                      style="text-decoration: underline;"><strong><span  >İLKER
                          DÖVİZ </span></strong></span></a> <span
                    style="color: rgb(192, 192, 192);"> Kurları İçin Tıklayınız</span></span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 col-sm-12">
        <div class="wItem" style="background-color: rgb(0, 0, 0); ">
          <div>
            <div class="col" style="color: rgb(255, 255, 255); font-size: 17px; font-weight: 500; height: 35px; ">
              <span style="position: relative; top:0.4em ;"> {{ now| date:"fullDate":"":"tr-TR" }} {{now | date:'hh:mm:ss':"":"tr-TR"}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
