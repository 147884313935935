<div class="row g-0 h-1">
  <div class="col-12 pr  " style=" margin: 50px 0px;">
    <div class="pritem h-100 ">
      <div class="g-0 row">
        <div class="col-md-6 col-sm-12" style="text-align: unset;">
          <main class="container ">




            <ul class="carousel fadingCarousel">
              <input type="radio" checked id="1" name="f_activator" class="carousel__activator" />
              <input type="radio" id="2" name="f_activator" class="carousel__activator" />
              <input type="radio" id="3" name="f_activator" class="carousel__activator" />
              <input type="radio" id="4" name="f_activator" class="carousel__activator" />

              <!--       <div class="carousel__controls">
                <label for="1" class="carousel__control carousel__control--backward">
                  <i class="material-icons">keyboard_arrow_left</i>
                </label>
                <label for="2" class="carousel__control carousel__control--forward">
                  <i class="material-icons">keyboard_arrow_right</i>
                </label>
              </div>
              <div class="carousel__controls">
                <label for="4" class="carousel__control carousel__control--backward">
                  <i class="material-icons">keyboard_arrow_left</i>
                </label>
                <label for="3" class="carousel__control carousel__control--forward">
                  <i class="material-icons">keyboard_arrow_right</i>
                </label>
              </div>
             <div class="carousel__controls">
                <label for="3" class="carousel__control carousel__control--backward">
                  <i class="material-icons">keyboard_arrow_left</i>
                </label>
                <label for="2" class="carousel__control carousel__control--forward">
                  <i class="material-icons">keyboard_arrow_right</i>
                </label>
              </div>


              <div class="carousel__controls">
                <label for="1" class="carousel__control carousel__control--backward">
                  <i class="material-icons">keyboard_arrow_left</i>
                </label>
                <label for="2" class="carousel__control carousel__control--forward">
                  <i class="material-icons">keyboard_arrow_right</i>
                </label>
              </div>  -->

              <li class="carousel__slide">
                <img class="carousel__slide--content" src="../../../../assets/image/carousel/1.png" />
              </li>
              <li class="carousel__slide">
                <img class="carousel__slide--content" src="../../../../assets/image/carousel/2.png" />
              </li>
              <li class="carousel__slide">
                <img class="carousel__slide--content" src="../../../../assets/image/carousel/3.jpeg" />
              </li>
              <li class="carousel__slide">
                <img class="carousel__slide--content" src="../../../../assets/image/carousel/4.png" />
              </li>
              <li class="carousel__slide">
                <img class="carousel__slide--content" src="../../../../assets/image/carousel/5.png" />
              </li>
              <div class="carousel__indicators">
                <label for="1" class="carousel__indicator"></label>
                <label for="2" class="carousel__indicator"></label>
                <label for="3" class="carousel__indicator"></label>
                <label for="4" class="carousel__indicator"></label>
              </div>
            </ul>





            <!-- <section class="carousel">
              <div class="carousel__item " id="carousel-item-1">
                <img src="../../../../assets/image/carousel/1.png" alt="">
              </div>
              <div class="carousel__item" id="carousel-item-2">
                <img src="../../../../assets/image/carousel/2.png" alt="">
              </div>
              <div class="carousel__item" id="carousel-item-3">
                <img src="../../../../assets/image/carousel/3.jpeg" alt="">
              </div>
              <div class="carousel__item" id="carousel-item-4">
                <img src="../../../../assets/image/carousel/4.png" alt="">
              </div>
              <div class="carousel__item" id="carousel-item-5">
                <img src="../../../../assets/image/carousel/5.png" alt="">
              </div>
            </section>
            <nav class="pagination">
              <a class="pagination__link" href="#carousel-item-1">1</a>
              <a class="pagination__link" href="#carousel-item-2">2</a>
              <a class="pagination__link" href="#carousel-item-3">3</a>
              <a class="pagination__link" href="#carousel-item-4">4</a>
              <a class="pagination__link" href="#carousel-item-5">5</a>
            </nav> -->
          </main>
        </div>



























        <div class="col-md-2 col-sm-12  " style="text-align: unset;">
          <div class="wItem"
            style="background-color: unset; border-style: solid; border-color: unset; border-width: 0px; padding: unset; margin: 75px 0px 0px 50px; border-radius: 0px; overflow: unset; box-shadow: unset;">
            <div>
              <div class="row m-0  ">
                <div class="itemrow text-center mb-25 col-md-6 col-sm-12  ">
                  <br><br><br>
                  <div class="features-wrap"
                    style="background-color: unset; border-style: unset; border-color: unset; border-width: unset; padding: 0px; border-radius: 0px; box-shadow: unset; cursor: pointer;">
                    <a href="https://www.instagram.com/safir_kuyum/" target="_blank">
                      <div class="features-icon"><img class="responsiveimg" src="../../../../assets/icon/isntagram.png"
                          style="width: 30px; height: 30px;"></div>
                      <h4 style="color: rgb(0, 0, 0); font-size: 15px;">
                        INSTAGRAM
                      </h4>
                    </a>
                  </div>
                </div>
                <div class="itemrow text-center mb-25 col-md-6 col-sm-12  ">
                  <a href="https://www.facebook.com/people/Safir-Kuyum/100009310088883/" target="_blank">
                    <div class="features-wrap"><br><br><br>

                      <div class="features-icon"><img class="responsiveimg" src="../../../../assets/icon/facebook,.png"
                          style="width: 30px; height: 30px;"></div>

                      <h4 style="color: rgb(0, 0, 0); font-size: 15px;">
                        FACEBOOK
                      </h4>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4 col-sm-12  " style="text-align: unset;">
          <div class="wItem"
            style="background-color: unset; border-style: solid; border-color: unset; border-width: 0px; padding: unset; margin: 25px 0px 0px 100px; border-radius: 0px; overflow: unset; box-shadow: unset;">
            <br><br><br><br>
            <div>
              <div class="contact-info-wrap">
                <div class="row m-0">
                  <address class="col-auto  ">
                    <i class="material-icons">phone </i>
                    <span> Telefon</span><a href="tel:+90 (342) 517 12 56">+90 (342) 517 12 56</a>
                  </address>
                </div>
                <div class="row m-0">
                </div>
                <div class="row m-0">
                  <address class="col-auto">
                    <i class="material-icons">location_on</i>
                    <span>Adres</span> Fatih Sultan Mehmet Bulvarı No:2 Hükümet
                    Konağı Karşısı 27700 Nizip/Gaziantep
                  </address>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="ng-star-inserted">
  <div class="ng-star-inserted">
    <div class="row g-0 h-100">
      <div class="col-12 pr ng-star-inserted" style="background: unset; height: unset; margin: 0px 0px 30px;">
        <div class="pritem h-100 container">
          <div class="g-0 justify-content-center row">
            <div class="col-md-9 col-sm-12 ng-star-inserted" style="text-align: center;">
              <div class="wItem"
                style="background-color: unset; border-style: solid; border-color: unset; border-width: 0px; padding: unset; margin: unset; border-radius: 15px; overflow: auto; box-shadow: rgba(0, 0, 0, 0.7) 0px 15px 30px;">
                <div class="ng-star-inserted">
                  <img class="responsiveimg" src="../../../../assets/image/carousel/reference.gif" style="width: 100%;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
