<app-navbar></app-navbar>
<iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3185.9996268578684!2d37.79476231563727!3d37.0098052635717!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1531e13785f5e5fd%3A0x69f284a0b3bbe0ce!2sSafir%20Kuyumculuk%20%26%20%C4%B0lker%20D%C3%B6viz!5e0!3m2!1str!2str!4v1659443016454!5m2!1str!2str"
  class="w-100" style="height:50vh; margin-top: 2em;"></iframe>
<div class="col-12" style="background: unset; height: unset;">
  <div class="pritem h-100 container">
    <div class="g-0 row">
      <div class="col-12">
        <div class="wItem" style="margin: 50px 0px 0px;">
          <div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="title" style="color: rgb(0, 0, 0); font-size: 25px; font-weight: bold;">
                  Safir Kuyumculuk Kıymetli Madenler San. Tic. Ltd. Şti</div>
                <div class="borderout row m-0 justify-content-center">
                  <div class="border"
                    style="background-color: rgb(196, 156, 72); height: 5px; width:200px; margin-top: 1em;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="align-self-center col-12">
        <div class="wItem" style="margin: 50px 0px;  box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 30px;">
          <div>
            <div class="row p-0">
              <div class="text-center mb-25 col-md-6 col-sm-12">
                <div class="features-wrap">
                  <div class="features-icon">
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 30px;">phone</i>
                  </div>
                  <h4 style="color: rgb(41, 43, 44); font-size: 22px;">Telefon</h4>
                  <p style="color: rgb(41, 43, 44); font-size: 18px;">0342 512 10 10 / 0342 517
                    12 56 / 0342 518 20 72 / 0342 518 37 44--</p>
                </div>
              </div>
              <div class="text-center mb-25 col-md-6 col-sm-12">
                <div class="features-wrap">
                  <div class="features-icon">
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 30px;">mail</i>
                  </div>
                  <h4 style="color: rgb(41, 43, 44); font-size: 22px;">E-Mail</h4>
                  <p style="color: rgb(41, 43, 44); font-size: 18px;">safirgold@hotmail.com</p>
                </div>
              </div>
              <div class="text-center mb-25 col-md-6 col-sm-12">
                <div class="features-wrap">
                  <div class="features-icon"><br>
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 40px;">fax</i>
                  </div><br>
                  <h4 style="font-size: 22px;">Fax</h4>
                  <p style="font-size: 18px;">0342 517 18 81</p>
                </div>
              </div>
              <div class="text-center mb-25 col-md-6 col-sm-12">
                <div class="features-wrap">
                  <div class="features-icon"><br><br>
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 50px;">pin_drop</i>
                  </div><br><br>
                  <h4 style="color: rgb(41, 43, 44); font-size: 22px;">Adres</h4>
                  <p style="color: rgb(41, 43, 44); font-size: 18px;">Fatih Sultan Mehmet Bulvarı
                    No:2 Hükümet Konağı Karşısı 27700 Nizip/Gaziantep</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="col-12 pr" style="background: unset; height: unset;">
  <div class="pritem h-100 container">
    <div class="g-0 row">
      <div class="col-12" style="text-align: unset;">
        <div class="wItem" style=" margin: 50px 0px; ">
          <div>
            <div class="row">
              <div class="col-12 text-center">
                <div class="title" style="color: rgb(0, 0, 0); font-size: 25px; font-weight: bold;">İlker Döviz ve Altın
                  Sınırlı Yetkili Müessese A.Ş.</div>
                <div class="borderout row m-0 justify-content-center">
                  <div class="border" style="background-color: rgb(196, 156, 72); height: 5px; width: 200px; margin-top: 1em;"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12" style="text-align: unset;">
        <div class="wItem" style="margin: 0px 0px 50px; box-shadow: rgba(0, 0, 0, 0.5) 0px 15px 30px;">
          <div>
            <div class="row p-0">
              <div class="text-center mb-25 col-md-6 col-sm-12">
                <div class="features-wrap">
                  <div class="features-icon">
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 30px;">phone</i>
                  </div>
                  <h4 style="color: rgb(0, 0, 0); font-size: 20px;">Telefon</h4>
                  <p style="color: rgb(0, 0, 0); font-size: 18px;">0342 512 10 10 - 0342 517 12
                    56</p>
                </div>
              </div>
              <div class="text-center mb-25 col-md-6 col-sm-12">
                <div class="features-wrap">
                  <div class="features-icon">
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 30px;">mail</i>
                  </div>
                  <h4 style="color: rgb(0, 0, 0); font-size: 20px;">Mail</h4>
                  <p style="color: rgb(0, 0, 0); font-size: 18px;">ilkerdoviznizip@hotmail.com
                  </p>
                </div>
              </div>
              <div class="text-center mb-25 col-12">
                <div class="features-wrap">
                  <div class="features-icon">
                    <i class="material-icons" style="color: rgb(196, 156, 72); font-size: 50px;">pin_drop</i>
                  </div>
                  <h4 style="color: rgb(0, 0, 0); font-size: 20px;">Adres</h4>
                  <p style="color: rgb(0, 0, 0); font-size: 18px;">Fevzipaşa Mah. Fatih Sultan
                    Mehmet Bulvarı No:2 Nizip/Gaziantep</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
