<div class="ng-star-inserted">
  <div class="ng-star-inserted">
    <div class="row g-0 h-100">
      <div class="col-12 pr ng-star-inserted"
        style="background-image: unset; background-position-x: 50%; background-position-y: unset; background-size: unset; background-repeat: no-repeat; background-attachment: scroll; background-origin: unset; background-clip: unset; background-color: unset; height: unset;">

        <div class="pritem h-100 container">
          <div class="g-0 row">
            <div class="col-md-6 col-sm-12 ng-star-inserted" style="text-align: center;">
              <div class="wItem"
                style="background-color: unset; border-style: solid; border-color: unset; border-width: 0px; padding: unset; margin: 50px 0px 0px; border-radius: 0px; overflow: unset; box-shadow: unset;">
                <div class="ng-star-inserted">
                  <div class="e-rte-content" style="color: rgb(255, 255, 255);">
                    <p style="text-align: center;"><span style="font-size: 12pt;"><span
                          style="color: rgb(0, 0, 0); text-decoration: inherit;">Has Altın ve Paketli Gram Altın
                          Satışlarında </span></span><strong><span style="font-size: 12pt;"><span
                            style="color: rgb(0, 0, 0); text-decoration: inherit;">NAKİT ÖDEME
                            GEÇERLİDİR</span></span></strong><span style="font-size: 12pt;"><span
                          style="color: rgb(0, 0, 0); text-decoration: inherit;">&nbsp;</span></span></p>
                    <p style="text-align: center;"><span style="font-size: 12pt;"><span
                          style="color: rgb(0, 0, 0); text-decoration: inherit;">Kredi Kartı - Banka Kartı Veya Havale
                          İle İşlem </span></span><strong><span style="font-size: 12pt;"><span
                            style="color: rgb(0, 0, 0); text-decoration: inherit;">GEÇERLİ
                            DEĞİLDİR</span></span></strong></p>
                  </div>
                </div>
              </div>
            </div>
            <div class="align-self-center col-md-6 col-sm-12 ng-star-inserted" style="text-align: center;">
              <div class="wItem"
                style="background-color: unset; border-style: solid; border-color: unset; border-width: 0px; padding: unset; margin: 50px 0px 0px; border-radius: 0px; overflow: unset; box-shadow: unset;">
                <div class="ng-star-inserted">
                  <div class="e-rte-content" style="color: rgb(255, 255, 255);">
                    <p><span style="color: rgb(255, 0, 0); text-decoration: inherit;"><strong>Yasal Uyarı:
                        </strong></span><span style="color: rgb(0, 0, 0); text-decoration: inherit;">Burada yer alan
                        Haberler, Döviz Kurları ve Altın fiyatları Bilgi Amaçlıdır.</span></p>
                    <p>
                      <font color="#000000">İşlem anında güncel kurlar geçerlidir.</font>
                    </p>
                    <p><span style="color: rgb(0, 0, 0); text-decoration: inherit;">Yayınlanan bilgiler yatırım
                        danışmanlığı kapsamında değildir.</span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
