<nav class="navbar navbar-expand-md  ">
  <img src="../../../../assets/image/logo/banner-logo.png" class="navbar-brand" width="320" height="80">
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
    (click)="toggleNavbar()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown" [ngClass]="{ 'show': isCollapsed }">
    <ul class="navbar-nav" style="margin-left: 10em;">
      
      <li><a routerLink="/ekran">EKRAN</a></li>
    </ul>
  </div>
</nav>
