<ul class="navbar-nav">
  <li class="nav-item mr-2  d-none d-lg-block">
    <a *ngIf="toggleClass === 'ft-maximize'" href="javascript:;" class="nav-link" (click)="openFullscreen()">
      <img src="../../../../assets/image/logo/banner-logo.png" class="p-4" width="500" style="margin-left:35%;">

    </a>
    <a *ngIf="toggleClass === 'ft-minimize'" href="javascript:;" class="nav-link" (click)="closeFullscreen()">
      <img src="../../../../assets/image/logo/banner-logo.png" class="p-4" width="500" style="margin-left:35%;">

    </a>
  </li>
</ul>
<div class="row w-100 md-4 p-4">
  <div class="bods col  col-lg-6">
    <div class="card">
      <table class="material-table">
        <thead>
          <tr>
            <th style="font-size: 24px;">SARRAFIYE <br> <span style="font-size:0.8em ;"> ESKI TARIHLI</span></th>
            <th style="font-size: 24px;">ALIŞ</th>
            <th style="font-size: 24px;">SATIŞ</th>
            <th style="font-size: 24px;"></th>
          </tr>
        </thead>
        <tbody *ngFor="let socketData of goldList; let i=index; trackBy : trackByPrice" [ngClass]="{'grid-up': socketData.askPercentChange >0,'grid-stabil': socketData.askPercentChange ===0,
               'grid-down': socketData.askPercentChange <0}">
          <tr>
            <td> <span>{{socketData.Code}}</span> <br />
              <span style="font-size:0.75em ;"> {{socketData.Description}} </span>
            </td>
            <td style="font-size: 30px;">{{socketData.Bid | number:'1.2-4' }}</td>

            <td style="font-size: 30px;">{{socketData.Ask | number:'1.2-4' }} </td>
            <td style="margin-left:70% ;font-size: 30px;">
              <i class="material-icons" *ngIf="socketData.askPercentChange > 0">keyboard_arrow_up</i>
              <i class="material-icons" *ngIf="socketData.askPercentChange == 0">remove</i>
              <i class="material-icons" *ngIf="socketData.askPercentChange < 0">keyboard_arrow_down</i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="bods col col-lg-6">
    <div class="card">
      <table class="material-table">
        <thead>
          <tr>
            <th style="font-size: 24px;">GRAM ALTIN <br><span style="font-size:0.8em ;"> FİYATLARI </span> </th>
            <th style="font-size: 24px;">ALIŞ</th>
            <th style="font-size: 24px;">SATIŞ</th>
            <th style="font-size: 24px;"></th>
          </tr>
        </thead>
        <tbody *ngFor="let gramadata of currencyList; let i=index; trackBy : trackByPrice" [ngClass]="{'up': gramadata.askPercentChange > 0,'grid-stabil': gramadata.askPercentChange ===0,
          'grid-down': gramadata.askPercentChange <0}">
          <tr>
            <td> <span>{{gramadata.Code}}</span> <br />
              <span style="font-size:0.75em ;"> {{gramadata.Description}} </span>
            </td>
            <td style="font-size: 30px;">{{gramadata.Bid| number:'1.2-4' }}</td>
            <td style="font-size: 30px;">{{gramadata.Ask| number:'1.2-4' }}</td>
            <td style="font-size: 30px;">
              <i class="material-icons" *ngIf="gramadata.askPercentChange > 0">keyboard_arrow_up</i>
              <i class="material-icons" *ngIf="gramadata.askPercentChange == 0">remove</i>
              <i class="material-icons" *ngIf="gramadata.askPercentChange < 0">keyboard_arrow_down</i>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
