<app-navbar></app-navbar>
<div class="col-12" style="background-image: url(../../../../assets/image/banner/aaa.png);   height: 73px;">
  <div class=" " style="height: 73px; background-color: rgba(61, 61, 61, 0.7);">
    <div class="title" style="font-size: 32px; font-weight: bold;">
    </div>
    <div class=" ">
      <div class="g-0 row">
        <div class="row">
          <div class="col-12 text-center">
            <div class="title" style="color: rgb(255, 255, 255); font-size: 32px; font-weight: bold;"> <br>
              HAKKIMIZDA
            </div>
            <div class=" row m-0 justify-content-center">
              <div style="background-color: rgb(196, 156, 72); margin-top: 1em; height: 5px; width: 150px;"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <article id="timeline">
    <ul class="timeline" id="bSTime">
      <li>
        <div class="timeline-badge">
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">1965</h4>
          </div>
          <div class="timeline-body">
            <p>SAFİR KUYUMCULUK TEMELLERİ SAFFET KAYA TARAFINDAN ATILDI.</p>
          </div>
        </div>
      </li>

      <li class="timeline-inverted">
        <div class="timeline-badge ">
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">1990</h4>
          </div>
          <div class="timeline-body">
            <p>TOPTAN KÜLÇE ALTIN PİYASASINA GİRİŞ.</p>
          </div>
        </div>
      </li>

      <li>
        <div class="timeline-badge">
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">1995</h4>
          </div>
          <div class="timeline-body">
            <p>YILLIK 1 TON ALTIN İŞLEME KAPASİTELİ ALTINKAYA BİLEZİK İMALATI KURULUŞ. </p>
          </div>
        </div>
      </li>

      <li class="timeline-inverted">
        <div class="timeline-badge ">
          <!-- <i class="material-icons">beenhere</i> -->
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">2004</h4>
          </div>
          <div class="timeline-body">
            <p>İLKER DÖVİZ VE ALTIN TİC. A.Ş. BÜNYESİNE KATARAK DÖVİZ HİZMETLERİNE BAŞLAMIŞTIR. </p>
          </div>
        </div>
      </li>
      <li>
        <div class="timeline-badge">
          <!-- <i class="material-icons">local_laundry_service</i> -->
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">2016</h4>
          </div>
          <div class="timeline-body">
            <p>İSTANBUL MERTER İVME ÖRME KUMAŞ TEKSTİL İMALATI KURULUŞ. </p>
          </div>
        </div>
      </li>
      <li class="timeline-inverted">
        <div class="timeline-badge">
          <!-- <i class="material-icons">view_carousel</i> -->
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">2018</h4>
          </div>
          <div class="timeline-body">
            <p>ÇUKUROVA ADANA KUYUMCULUK FUARI KATILIM SAĞLANDI. </p>
          </div>
        </div>
      </li>
      <li>
        <div class="timeline-badge">
          <!-- <i class="material-icons">panorama_vertical</i> -->
        </div>
        <div class="timeline-panel">
          <div class="timeline-heading">
            <h4 class="timeline-title">2019</h4>
          </div>
          <div class="timeline-body">
            <p> ORTADOĞU GAZİANTEP KUYUMCULUK FUARI KATILIM SAĞLANDI. </p>
          </div>
        </div>
      </li>
    </ul>
  </article>
</div>
<div>
  <div class="row g-0 h-100" style="margin-left: 2em;">
    <div class="col-12 pr" style="background: unset; height: unset; margin: 50px 0px;">
      <div class="pritem h-100 container" style="background-color:transparent;">
        <div class="g-0 row" style="background-color:transparent;">
          <div class="col-md-6 col-sm-12" style="background-color:transparent;">
            <div class="wItem" style=" margin: 0px 25px 0px 0px;">

              <div>
                <div><img class="responsiveimg" src="../../../../assets/image/context/about-context-1.jpeg"
                    style="width: 100%;">

                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12" style="text-align: unset;">
            <div class="wItem" style=" margin: 0px 25px 0px 0px;">

              <div>
                <div><img class="responsiveimg" src="../../../../assets/image/context/about-context-2.jpeg"
                    style="width: 100%;">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
