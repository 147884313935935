 <footer class="footer-area section-padding-2 blue-bg-2"
  style="background-image: unset; background-position: unset; background-size: contain; background-repeat: unset; background-attachment: unset; background-origin: unset; background-clip: unset; background-color: rgb(3, 3, 3); height: 200px;">
  <div class="h-100 container">
    <div class="row footerarea h-100 justify-content-center align-items-center">
      <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-12">
        <div class="footer-logo"><a href="/" class="navbar-brand"><img
              src="../../../../assets/image/logo/footer-logo.png" style="height: 75px; width: 320px;"> </a></div>
      </div>
      <div class="col-xl-10 col-lg-10 col-md-9 col-sm-9 col-12"> </div>
    </div>
  </div>
</footer>
